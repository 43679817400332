<template>
  <div>
    <transition name="popupBottomMove">
      <div class="popup-bottom" ref="popup">
        <!--页面顶部-->
        <div class="popup-bottom-title">
          <p @click="back" class="popup-bottom-title-button">
            <Button shape="circle" type="primary" size="large" :icon="'ios-arrow-down'">关闭</Button>
          </p>
          <div class="popup-bottom-title-steps">
            <div style="width: 80%">
              <Steps :current="stepIndex">
                <Step :title="item.title" :content="item.content" v-for="(item,index) in steps" :key="item.id"></Step>
              </Steps>
            </div>
          </div>
          <div class="popup-bottom-time">{{ backTime }}s</div>
        </div>
        <!--页面内容-->
        <div class="popup-bottom-body" ref="popupBottomWrapper">
          <div>
            <Carousel v-model="stepIndex" dots="none" arrow="never">
              <!--核验人证信息-->
              <CarouselItem>
                <loadingPage v-show="loadingInit"></loadingPage>
                <id-card v-show="!loadingInit" @sure="sureIdCard" @again="backTime = 300" @wzOpen="wzOpen"
                         @wzHide="wzHide" :userId="userId"></id-card>
              </CarouselItem>
              <!--输入姓名-->
              <CarouselItem>
                <Name v-if="stepIndex === 1" @sureName="sureName"></Name>
              </CarouselItem>
              <!--扫描二维码-->
              <CarouselItem>
                <search-obj-w-s v-if="stepIndex === 2" @sendUseObj="gerUserObj" @back="back"></search-obj-w-s>
              </CarouselItem>
              <!--付款-->
              <CarouselItem>
                <div v-if="stepIndex === 3" style="padding: 24px 168px">
                  <List border>
                    <div slot="header">
                      <h5 style="font-size: 18px;text-align: center">请确认订单信息</h5>
                    </div>
                    <ListItem><p class="order-item">房型<span>{{ orderObj.roomTypeName }}</span></p></ListItem>
                    <ListItem><p class="order-item">房间<span>{{ orderObj.roomInfoName }}</span></p></ListItem>
                    <ListItem><p class="order-item">入住时间<span>{{ orderObj.orderInTime }}</span></p></ListItem>
                    <ListItem><p class="order-item">离店时间<span>{{ orderObj.orderOutTime }}</span></p></ListItem>
                    <ListItem v-if="orderObj.orderType === 3"><p class="order-item">押金<span
                        style="color: #ed4014;font-size: 18px;font-weight: 600">￥{{
                        orderObj.allDeposit / 100 | goodOverFlow
                      }}</span>
                    </p></ListItem>
                    <ListItem><p class="order-item">提示<span style="color: #ed4014;font-size: 16px;font-weight: 600">押金会在房间打扫完成后原路退还</span>
                    </p></ListItem>
                  </List>
                  <div v-if="orderObj.allDeposit !== 0 && orderObj.orderType !== 1"
                       style="display: flex;align-items: center;justify-content: center;margin-top: 60px">
                    <Button type="primary" long size="large"
                            style="margin-right: 20%;background-color: #2d8cf0;border-color: #2d8cf0" @click="aliPay"
                            :loading="buttonLoading">
                      支付宝支付
                    </Button>
                    <Button type="success" long size="large" @click="wxPay" :loading="buttonLoading">微信支付</Button>
                  </div>
                  <div v-if="orderObj.allDeposit === 0 || orderObj.orderType === 1">
                    <Button type="primary" style="background-color: #bba37f;border-color: #bba37f;margin-top: 60px" long
                            size="large" @click="inRoom" :loading="buttonLoading">确认办理
                    </Button>
                  </div>
                </div>
              </CarouselItem>
              <!--操作流程指导-->
              <CarouselItem>
                <guide v-if="stepIndex === 4" :roomNum="roomNum"></guide>
              </CarouselItem>
            </Carousel>
          </div>
        </div>
      </div>
    </transition>
    <!--微信支付-->
    <Modal v-model="flagObj.wx" title="微信支付" :mask-closable="false" @on-cancel="closeWxPay">
      <canvas ref="qrccodeCanvas" style="display: none"></canvas>
      <div class="pay-img">
        <div>
          <div class="pay-img-icon-wrapper">
            <img class="pay-img-icon" src="../../assets/wxpay.svg">
          </div>
          <div class="pay-img-icon-wrapper">
            <img class="pay-img-qr" :src="wxPayImg" style="height: 300px">
          </div>
          <p>若已支付，请点击下面"我已支付"按钮</p>
          <Button type="success" style="margin-top: 10px" long size="large" @click="paySuccess">我已支付</Button>
        </div>
      </div>
      <div slot="footer">
      </div>
    </Modal>
    <!--支付宝支付-->
    <Modal v-model="flagObj.ali" title="支付宝支付" :mask-closable="false" @on-cancel="closeAliPay">
      <canvas ref="qrccodeCanvas" style="display: none"></canvas>
      <div class="pay-img">
        <div>
          <canvas ref="qrccodeCanvas" style="display: none"></canvas>
          <div class="pay-img-icon-wrapper">
            <img class="pay-img-icon" src="../../assets/alipay.svg">
          </div>
          <div class="pay-img-icon-wrapper">
            <img class="pay-img-qr" :src="aliPayImg" style="height: 300px">
          </div>
          <p>若已支付，请点击下面"我已支付"按钮</p>
          <Button type="info" style="margin-top: 10px" long size="large" @click="paySuccess">我已支付</Button>
        </div>
      </div>
      <div slot="footer">
      </div>
    </Modal>
    <!--多条订单 选择房间办理-->
    <Modal v-model="multiplePT" width="570">
      <p slot="header" style="text-align:center">
        <span>原分配的房间正在打扫，请选择同房型的其他房间办理入住</span>
      </p>
      <div class="order-list-wrapper">
        <Card v-for="item in orderArr" :key="item.id" style="margin: 15px">
          <div class="order-list">
            <div>
              <h4>{{ item.roomTypeName }} {{ item.roomInfoName }}</h4>
            </div>
            <div>
              <Button type="success" @click="activeOrder(item)">点击办理</Button>
            </div>
          </div>
        </Card>
      </div>
      <div slot="footer"></div>
    </Modal>
    <!--核验订单中-->
    <Modal v-model="flagObj.pass" width="360" class-name="vertical-center-modal" :closable="false" footer-hide
           :mask-closable="false">
      <p slot="header" style="text-align: center;">正在核验,请稍后</p>
      <div style="padding: 30px">
        <Spin>
          <Icon type="ios-loading" size="60" class="demo-spin-icon-load"></Icon>
          <div style="font-size: 20px;margin-top: 20px">核验中...</div>
        </Spin>
      </div>
    </Modal>
    <!--输入手机号码 验证重名-->
    <Modal v-model="flagObj.phone" width="360" :closable="false" :mask-closable="false">
      <p slot="header" style="text-align: center;">请输入手机号码</p>
      <div style="padding: 15px">
        <Input v-model="phone" placeholder="请输入手机号码"></Input>
      </div>
      <div slot="footer">
        <Button type="success" size="large" long @click="surePhone">确定</Button>
      </div>
    </Modal>
    <!--没有找到订单 弹出二维码扫描 或者联系商家-->
    <Modal v-model="flagObj.noOrder" width="540" :closable="false" :mask-closable="false">
      <p slot="header" style="text-align: center;">没有找到订单</p>
      <div style="display: flex;align-items: center;justify-content: center">
        <img src="../../assets/noOrder.jpg" style="width: 250px">
      </div>
      <h4 v-if="hotelObj" style="font-size: 18px;letter-spacing: 2px;line-height: 150%;padding: 15px">
        没有找到订单,若是无疆电竞微信小程序预订订单,请扫描二维码查看入住人名字是否正确。若是其他订单,请联系商家:{{ hotelObj.hotelServicePhone }}</h4>
      <div slot="footer">
        <Button type="error" size="large" long @click="back">关闭</Button>
      </div>
    </Modal>
    <!--显示房间-->
    <template v-if="hotelObj">
      <Modal title="请选择房间" v-model="flagObj.room" width="800">
        <div style="display: flex;flex-wrap: wrap">
          <span v-for="(item) in roomList" :key="item.roomInfoId" class="room-div"
                @click="sureRoom(item)">{{ item.name }}</span>
        </div>
        <div slot="footer"></div>
      </Modal>
    </template>
  </div>
</template>

<script>
import idCard from "../idCard/idCard"
import guide from "../guide/guide"
import Phone from "../phone/phone"
import Name from "../name/name"
import searchObjWS from "../searchObjWS/searchObjWS"
import loadingPage from "../loadingPage/loadingPage"
import {getLocalStorage, setLocalStorage} from "../../common/js/localStorage"
import {baseUrl} from "../../common/js/request"
import QRCode from 'qrcode'
import {calculationDateForDay} from "../../common/js/calculationDate"

export default {
  name: "BL",
  components: {
    Phone,
    idCard,
    Name,
    loadingPage,
    guide,
    searchObjWS
  },
  data() {
    return {
      hotelObj: null,
      hotelDetail: null,
      hotelId: null,
      steps: [
        {
          id: 0,
          title: '进行中',
          content: '人证核验'
        },
        {
          id: 1,
          title: '待进行',
          content: '输入下单姓名'
        },
        {
          id: 2,
          title: '待进行',
          content: '扫描二维码'
        },
        {
          id: 3,
          title: '待进行',
          content: '确认订单'
        },
        {
          id: 4,
          title: '待进行',
          content: '开门指引'
        }
      ],
      stepIndex: 0,
      flagObj: {
        wx: false,
        ali: false,
        pass: false,
        phone: false,
        noOrder: false,
        room: false
      },
      typeArrow: "never",
      typeObj: null,
      roomObj: null,
      phone: null,
      name: null,
      idCardArr: [],
      orderArr: [],
      orderObj: {},
      backTime: 300,
      intervalTime: null,
      multiplePT: false,
      aliPayImg: null,
      wxPayImg: null,
      loadingInit: true,
      showWZ: false,
      userId: 0,
      userOpenId: 0,
      roomNum: 0,
      buttonLoading: false,
      hotelServicePhone: 0,
      roomList: [],
      shareId: 0,
      socket: null,
      wsHeart: null
    }
  },
  methods: {
    /*返回首页*/
    back() {
      this.stepIndex = 0
      this.steps[this.stepIndex].title = "进行中"
      this.steps[this.stepIndex + 1].title = "待进行"
      clearInterval(this.intervalTime)
      this.$emit('back')
    },
    /*点击忘证入住*/
    wzOpen() {
      this.backTime = 300
    },
    /*关闭忘证入住*/
    wzHide() {
      this.backTime = 300
    },
    /*确定身份证核验*/
    sureIdCard(val) {
      console.log(val)
      this.idCardArr = val
      this.backTime = 300
      this.selectOrderByNameIdCard()
    },
    /*绑定入住人*/
    bindingGuest() {
      this.$http.post('/users/add/guest/list', this.idCardArr).then(res => {
      })
    },
    // 确定姓名
    sureName(val) {
      this.name = val
      this.backTime = 300
      let self = this
      let obj = {
        username: this.name,
        hotelId: this.hotelId,
      }
      this.$http.get('/order/username/query/notHandle/order', {
        params: obj
      }).then(res => {
        self.orderArr = res.result
        if (res.result.length === 0) {
          self.flagObj.noOrder = true
        }
        if (res.result.length === 1) {
          self.orderObj = self.orderArr[0]
          self.passOrder()
        }
        if (res.result.length > 1) {
          self.ifName()
        }
      })
    },
    /*通过身份证姓名查询订单*/
    selectOrderByNameIdCard() {
      let self = this
      self.orderArr = []
      this.idCardArr.forEach((item, index) => {
        let obj = {
          username: item.name,
          hotelId: this.hotelId,
        }
        this.$http.get('/order/username/query/notHandle/order', {
          params: obj
        }).then(res => {
          res.result.forEach((ri) => {
            self.orderArr.push(ri)
          })
          setTimeout(() => {
            if (self.idCardArr.length === (index + 1)) {
              if (self.orderArr.length === 0) {
                self.stepIndex = 1
                self.steps[self.stepIndex].title = "进行中"
                self.steps[self.stepIndex - 1].title = "已完成"
              }
              if (self.orderArr.length === 1) {
                self.orderObj = self.orderArr[0]
                self.passOrder()
              }
              if (self.orderArr.length > 1) {
                let flag = true
                self.orderArr.forEach((item) => {
                  if (self.orderArr[0].username !== item.username) {
                    flag = false
                  }
                })
                if (flag) {
                  self.ifName()
                } else {
                  self.stepIndex = 1
                  self.steps[self.stepIndex].title = "进行中"
                  self.steps[self.stepIndex - 1].title = "已完成"
                }
              }
            }
          }, 500)
        })
      })
    },
    //判断是否重名
    ifName() {
      let flag = false
      this.orderArr.forEach((item, index) => {
        //同一个人
        if (index > 0) {
          if (this.orderArr[0].userPhone === item.userPhone) {
            flag = true
          }
        }
      })
      if (flag) {
        this.multiplePT = true
      } else {
        this.flagObj.phone = true
      }
    },
    //确认手机号
    surePhone() {
      this.flagObj.phone = false
      let orderArr = []
      let orderList = []
      this.orderArr.forEach((item, index) => {
        if (item.userPhone === this.phone) {
          orderArr.push(item)
        } else {
          if (!item.userPhone) {
            orderList.push(item)
          }
        }
      })
      if (orderArr.length !== 0) {
        if (orderArr.length > 1) {
          this.multiplePT = true
          this.orderArr = orderArr
        } else {
          this.orderObj = orderArr[0]
          this.passOrder()
        }
      } else {
        if (orderList.length > 1) {
          this.multiplePT = true
          this.orderArr = orderList
        } else {
          if (orderList.length === 1) {
            this.orderObj = orderList[0]
            this.passOrder()
          } else {
            let self = this
            this.$Modal.error({
              title: '无法办理',
              content: '没有找到订单,请联系商家:' + this.hotelObj.hotelServicePhone,
              onOk: function () {
                self.back()
              }
            })
          }
        }
      }
    },
    //核验订单是否可以办理
    passOrder() {
      this.flagObj.pass = true
      let self = this
      console.log(this.orderObj)
      this.$http.post('/order/check/is/handel/in?masterId=' + this.orderObj.id).then(res => {
        setTimeout(() => {
          if (res.status === 200) {
            self.flagObj.pass = false
            self.stepIndex = 2
            self.steps[self.stepIndex].title = "进行中"
            self.steps[self.stepIndex - 1].title = "已完成"
            self.steps[self.stepIndex - 2].title = "已完成"
            self.roomNum = self.orderObj.roomInfoName
          } else if (res.status === 507) {
            self.getRoom()
          } else {
            self.flagObj.pass = false
            self.$Modal.error({
              title: '无法办理',
              content: '该订单未到入住时间,请联系商家:' + this.hotelObj.hotelServicePhone,
              onOk: function () {
                self.back()
              }
            })
          }
        }, 3000)
      })
    },
    //获取房型房间
    getRoom() {
      let day = calculationDateForDay(new Date(this.orderObj.orderInTime.replace(/-/g, '/')), new Date(this.orderObj.orderOutTime.replace(/-/g, '/')))
      let self = this
      let obj = {
        hotelId: this.hotelId,
        day: day.length,
        isReservation: 0,
        startTime: this.orderObj.orderInTime,
        endTime: this.orderObj.orderOutTime
      }
      this.$http.get('/home/hotel/room/type/info', {
        params: obj
      }).then(res => {
        self.flagObj.pass = false
        res.result.forEach((item) => {
          if (item.roomTypeId === self.orderObj.roomTypeId) {
            self.roomList = item.roomInfoVOList
          }
        })
        if (self.roomList.length === 0) {
          self.$Modal.error({
            title: '无法办理',
            content: '您预订的房间未完成打扫,请联系商家:' + this.hotelObj.hotelServicePhone,
            onOk: function () {
              self.back()
            }
          })
        } else {
          self.flagObj.room = true
        }
      })
    },
    /*确定选择，更换房间*/
    sureRoom(val) {
      let self = this
      let obj = {
        orderMasterId: this.orderObj.id,
        roomInfoId: val.roomInfoId
      }
      this.$http.post('/order/pc/change/room', {
        params: obj
      }).then(res => {
        self.flagObj.room = false
        self.flagObj.pass = false
        self.stepIndex = 3
        self.steps[self.stepIndex].title = "进行中"
        self.steps[self.stepIndex - 1].title = "已完成"
        self.steps[self.stepIndex - 2].title = "已完成"
        self.orderObj.roomInfoName = val.name
        self.roomNum = val.name
      })
    },
    // 点击办理
    activeOrder(val) {
      this.multiplePT = false
      this.orderObj = val
      this.passOrder()
    },
    /*获取userObj*/
    gerUserObj(user) {
      this.userId = user.userId
      this.userOpenId = user.userOpenId
      this.phone = user.phone
      this.stepIndex = 3
      this.steps[this.stepIndex].title = "进行中"
      this.steps[this.stepIndex - 1].title = "已完成"
      this.bindingGuest()
      this.bindUser()
    },
    //绑定入住人
    bindUser() {
      console.log(this.orderObj)
      let list = []
      this.idCardArr.forEach((item) => {
        list.push(item.idCardDto)
      })
      let obj = {
        cardDtoList: list,
        masterId: this.orderObj.id
      }
      this.$http.post('/order/bind/user/guest', obj).then(res => {
      })
      if (this.orderObj.orderType === 3) {
        let obj2 = {
          orderId: this.orderObj.id,
          userId: this.userId,
          phone: this.phone
        }
        this.$http.post('/order/bind/user/info', {
          params: obj2
        }).then(res => {
        })
      }
    },
    /*上传航信*/
    uploadHX() {
      let list = []
      this.idCardArr.forEach((item) => {
        let obj = {
          name: item.idCardDto.name,
          birthDay: item.idCardDto.birthDay,
          idNo: item.idCardDto.idNo,
          idCoverBase64: item.idCardDto.cover,
          nowCoverBase64: item.sitePhoto,
          address: item.idCardDto.address,
          validDate: item.idCardDto.validDate,
          issueAgency: item.idCardDto.issueAgency,
          nation: item.idCardDto.nation,
          sex: item.idCardDto.sex,
          type: item.idCardDto.type,
          city: item.idCardDto.city
        }
        list.push(obj)
      })
      let paramObj = {
        orderMasterId: this.orderObj.id,
        infoList: list,
        hotelId: this.hotelId,
        roomName: this.orderObj.roomInfoName
      }
      console.log(paramObj)
      this.$http.postV('/order/handle/hangxin/in', paramObj).then(res => {
      })
    },
    /*办理入住*/
    inRoom() {
      this.buttonLoading = true
      let self = this
      this.$http.post('/order/user/handle/order?orderId=' + this.orderObj.id).then(res => {
        self.buttonLoading = false
        self.goGuide()
      })
    },
    /*微信支付*/
    wxPay() {
      this.backTime = 300
      let self = this
      if (self.wxPayImg) {
        self.flagObj.wx = true
      } else {
        this.buttonLoading = true
        let self = this
        let obj = {
          orderId: this.orderObj.id,
          type: '1',
          hotelId: this.hotelId
        }
        this.$http.postFromData(baseUrl.dev + '/wx/mini/create/unlimit/wx/qrcode', {
          path: 'pages/codePay/codePay',
          scene: JSON.stringify(obj)
        }).then(res => {
          self.createWS()
          self.flagObj.wx = true
          self.buttonLoading = false
          self.wxPayImg = res
        })
      }
    },
    /*支付宝支付*/
    aliPay() {
      this.backTime = 300
      let self = this
      if (self.aliPayImg) {
        self.flagObj.ali = true
      } else {
        this.buttonLoading = true
        let self = this
        this.$http.post('/pay/ali/prepay/new/order?orderId=' + this.orderObj.id).then(res => {
          this.canvasDiv = this.$refs.qrccodeCanvas
          QRCode.toCanvas(this.canvasDiv, res.result, {
            width: 300
          }, (error) => {
            self.createWS()
            self.flagObj.ali = true
            self.buttonLoading = false
            if (error) {
              self.$Message.error('二维码生成失败')
            } else {
              let myCanvas = this.$refs.qrccodeCanvas
              self.aliPayImg = myCanvas.toDataURL('image/png')
            }
          })
        })
      }
    },
    /*关闭微信支付*/
    closeWxPay() {
      this.backTime = 300
      this.flagObj.wx = false
    },
    /*关闭支付宝支付*/
    closeAliPay() {
      this.backTime = 300
      this.flagObj.ali = false
    },
    /*页面倒计时*/
    pageOutTime() {
      this.intervalTime = setInterval(() => {
        this.backTime--
        if (this.backTime <= 0) {
          clearInterval(this.intervalTime)
          this.stepIndex = 0
          this.steps[0].title = "进行中"
          this.steps[1].title = "待进行"
          this.steps[2].title = "待进行"
          this.steps[3].title = "待进行"
          this.steps[4].title = "待进行"
          this.$emit('back')
        }
      }, 1000)
    },
    /*打开入住指引*/
    goGuide() {
      this.uploadHX()
      this.backTime = 60
      this.stepIndex = 4
      this.steps[this.stepIndex - 1].title = "已完成"
      if(this.orderObj.orderType == 1 && this.orderObj.userId != this.userId) {
        this.getRoomOpenList()
      }
    },
    //获取开门信息
    getRoomOpenList() {
      let self = this
      this.$http.get('/room/list/user/room?userId=' + this.orderObj.userId).then(res => {
        res.result.forEach((item) => {
          if(self.orderObj.id === item.orderMasterId) {
            self.getShare(item.id)
          }
        })
      })
    },
    /*获取分享ID*/
    getShare(id) {
      console.log('开始绑定')
      console.log(this.orderObj)
      let self = this
      this.$http.post('/room/user/share/room?openId=' + id + '&expiredTime=' + this.orderObj.orderOutTime).then(res => {
        self.shareId = res.result.id
        self.bindUserShare()
      })
    },
    // 绑定分享
    bindUserShare() {
      this.$http.post('/room/user/bind/share/room?openId=' + this.shareId + '&userId='+this.userId).then(res => {})
    },
    //  支付成功
    paySuccess() {
      if(this.orderObj.id) {
        let self = this
        this.$http.get('/order/get/master/des?orderId='+this.orderObj.id).then(res => {
          if(res.result.orderMaster.orderStatus > 1) {
            self.flagObj.wx = false
            self.flagObj.ali = false
            self.goGuide()
          } else {
            self.$Message.warning('请支付')
          }
        })
      }
    },
    /*建立websocket连接*/
    createWS() {
      if(typeof(WebSocket) === "undefined"){
        alert("您的浏览器不支持socket")
      }else{
        // 实例化socket
        this.socket = new WebSocket(baseUrl.wss)
        // 监听socket连接
        this.socket.onopen = this.WSOpen
        // 监听socket错误信息
        this.socket.onerror = this.WSError
        // 监听socket消息
        this.socket.onmessage = this.WSMessage
        this.socket.onclose = () => {
          console.log('websocket关闭了')
        }
      }
    },
    //ws注册
    registerWS() {
      let obj = {
        id: this.hotelId,
        sendDevice: "PC_CLIENT",
        acceptDevice: "MA_CLIENT",
        cmd: "ws_register",
        data: ""
      }
      this.socket.send(JSON.stringify(obj))
      this.sendHeartWS()
    },
    //ws心跳
    sendHeartWS() {
      this.wsHeart = setInterval(() => {
        let obj = {
          id: this.hotelId,
          sendDevice: "PC_CLIENT",
          acceptDevice: "MA_CLIENT",
          cmd: "ws_heart",
          data: ""
        }
        this.socket.send(JSON.stringify(obj))
      }, 30000)
    },
    // 监听socket消息
    WSMessage(res) {
      console.log('监听消息')
      const message = JSON.parse(res.data)
      if(message.cmd === 'order_pay_success') {
        this.closeWS()
        this.flagObj.wx = false
        this.flagObj.ali = false
        this.goGuide()
      }
    },
    /* 监听socket连接*/
    WSOpen() {
      console.log('连接成功')
      this.registerWS()
    },
    //关闭链接
    closeWS() {
      let obj = {
        id: this.hotelId,
        sendDevice: "PC_CLIENT",
        acceptDevice: "MA_CLIENT",
        cmd: "pc_close_ma",
        data: ""
      }
      if(this.socket) {
        this.socket.close()
        this.socket.send(JSON.stringify(obj))
      }
      clearInterval(this.wsHeart)
      console.log('关闭ws')
    },
    // 监听socket错误信息
    WSError() {
      console.log('连接失败')
    }
  },
  created() {
    let self = this
    setTimeout(() => {
      self.hotelId = getLocalStorage("zs-offline-hotelId")
      self.hotelObj = getLocalStorage("zs-offline-hotelInfoObj")
      self.hotelDetail = getLocalStorage("zs-offline-hotelDetailsObj")
      self.backTime = 300
      self.pageOutTime()
      setTimeout(() => {
        self.loadingInit = false
      }, 1000)
    }, 200)
  },
  filters: {
    goodOverFlow(val) {
      let v = parseFloat(val)
      return v.toFixed(2)
    },
  },
  beforeDestroy() {
    this.loadingInit = true
    this.$Modal.remove()
    clearInterval(this.intervalTime)
    this.closeWS()
  }
}
</script>

<style scoped lang="stylus">
.popup-bottom
  bottom 0
  left 0
  width 100%
  height 100%
  position fixed
  font-size 15px
  background-color #fff
  z-index 999
  overflow hidden

  .popup-bottom-title
    width 100%
    padding 18px 22px
    display flex
    background-color #ffffff
    border-bottom 1px solid #dcdee2

    .popup-bottom-title-steps
      width 100%
      display flex
      align-items center
      justify-content center

  .popup-bottom-body
    top 81px
    left 0
    width 100%
    height 100%
    position absolute
    overflow auto

.popup-bc
  top 0
  left 0
  width 100%
  height 100%
  position fixed
  z-index 998
  background-color rgba(0, 0, 0, .6)

.outTime
  margin 32px 0
  text-align center
  font-size 22px

.time-button
  margin-top 24px
  padding-bottom 81px
  width 100%
  display flex
  align-items center
  justify-content center

.type-item-wrapper
  padding 24px 60px
  display flex
  align-items center
  justify-content space-around
  flex-wrap wrap

  .type-item
    display flex
    align-items center
    justify-content flex-start

    .type-item-left
      width 168px
      height 168px
      margin-right 18px
      position relative
      overflow hidden
      border-radius 6px

      > img
        height 168px

      .type-item-surplus
        bottom 0
        left 0
        width 100%
        position absolute
        padding 8px
        background-color rgba(0, 0, 0, .5)
        color #ffffff
        text-align center
        font-size 14px

      .type-item-surplus-no
        bottom 0
        left 0
        width 100%
        position absolute
        padding 8px
        color #808695
        text-align center
        font-size 14px
        background-color rgba(197, 200, 206, .9)

  .type-item-right
    width calc(100% - 186px)
    height 100%

    h4
      margin-bottom 12px
      font-size 18px

    .price-day
      margin-top 12px
      display flex
      justify-content flex-end
      text-align right
      font-size 22px

      h5
        color #ed4014
        font-size 22px

.room-div
  margin 24px
  padding 12px 24px
  background-color #2d8cf0
  border-radius 4px
  color #ffffff

.order-item
  width 100%
  display flex
  align-items center
  justify-content space-between
  color #808695

  > span
    color #515a6e

.time-wrapper
  width 100%
  display flex
  align-items flex-start
  justify-content center

  .time-left
    width 20%
    height 100vh
    border-right: 1px solid #e8eaec

    > h4
      margin-top 32px
      text-align center
      font-size 22px

    .time-action
      width 100%
      height calc(100% - 200px)
      display flex
      align-items center

      .time-action-item
        width 150px
        height 150px
        margin 0 auto
        display flex
        align-items center
        justify-content center
        border-radius 50%
        background-color #808695
        color #ffffff
        font-size 22px

      .time-action-item-yes
        background-color #2d8cf0 !important

.pay-img
  display flex
  align-items center
  justify-content center

  p
    text-align center
    font-size 18px

  .pay-img-icon-wrapper
    display flex
    align-items center
    justify-content center

    .pay-img-icon
      width 150px
      height auto

.popup-bottom-time
  font-size 22px
  line-height 44px
  font-weight 600

.order-list-wrapper
  margin-top 5%
  padding 0 40px
  display flex
  align-items center
  justify-content space-between
  flex-wrap wrap

  .order-list
    width 400px
    padding 15px
    display flex
    align-items center
    justify-content space-between

    h4
      font-size 18px

.room-div
  margin 24px
  padding 12px 24px
  background-color #bba37f
  border-radius 4px
  color #ffffff

.popupBottomMove-enter-active, .popupBottomMove-leave-active
  transition all .4s

.popupBottomMove-enter, .popupBottomMove-leave-to
  transform translateY(100%)

.popupBottomShow-enter-active, .popupBottomShow-leave-active
  transition all .4s
  opacity 1

.popupBottomShow-enter, .popupBottomShow-leave-to
  opacity 0

.moveTop-enter-active, .moveTop-leave-active
  transition all .4s
  opacity 1

.moveTop-enter, .moveTop-leave-to
  transform translateY(100%)
  opacity 0

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}

.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}
</style>

<template>
  <div class="home-wrapper" @click="FullCreeen">
    <div v-show="!showLogo" class="home-logo">
      <img src="../assets/logo.png" @click.prevent="modalLogin = true">
      <p>无疆智慧订房系统</p>
    </div>
    <!--<div v-if="showAdveritsing" style="position: fixed;left: 30px;bottom: 30px;z-index: 9999999">
      <Button type="error" @click.prevent="openAdveritsing">开启流程介绍</Button>
    </div>-->
    <div class="home-title">
      <p>{{hotelName}}</p>
    </div>
    <div class="home-bg">
      <img class="home-bg-img" src="../assets/home.jpg">
      <div class="home-bg-blur"></div>
    </div>
    <div class="home-menu">
      <div class="home-menu-div">
        <div class="home-menu-div-top">
          <div @click="openPageBL">
            <div class="home-menu-div-top-img">
              <img src="../assets/logo.png">
              <img src="../assets/meituan.svg">
              <img src="../assets/xiecheng.svg">
              <img src="../assets/feizhu.svg">
            </div>
            <p style="font-size: 16px">无疆 美团 携程 等订单</p>
            <p style="margin-top: 5px;font-size: 32px;font-weight: 600;letter-spacing: 5px">我已预订</p>
          </div>
          <div @click="openPageXX">
            <img src="../assets/xx.png" style="height: 52px">
            <p style="font-size: 16px">现场订房</p>
            <p style="margin-top: 5px;font-size: 32px;font-weight: 600;letter-spacing: 5px">我要订房</p>
          </div>
        </div>
        <div class="home-menu-div-bottom">
          <div @click="openPageXF">
            <img src="../assets/xufang.png">
            <p style="font-size: 28px;font-weight: 400;letter-spacing: 5px">续房</p>
          </div>
          <div @click="openPageTF">
            <img src="../assets/tf.png">
            <p style="font-size: 28px;font-weight: 400;letter-spacing: 5px">退房</p>
          </div>
          <div v-if="hotelId == 1550510834189393922" @click="openPageDoor">
            <img src="../assets/door.png">
            <p style="font-size: 28px;font-weight: 400;letter-spacing: 2px">开启大门门禁</p>
          </div>
          <div v-if="hotelId != 1550510834189393922" @click="openPageRZR">
            <img src="../assets/user.png">
            <p style="font-size: 28px;font-weight: 400;letter-spacing: 2px">补录入住人</p>
          </div>
        </div>
      </div>
    </div>
    <div class="home-footer">
      <p style="color: #f1f1f1;margin-right: 15px">无疆加盟电话：15308097396，13408549556</p>
      &copy; 成都掌宿信息科技有限公司版权所有
    </div>
    <div class="home-phone">
      酒店电话: {{hotelPhone}}
    </div>
    <transition name="popupBottomMove">
      <div v-if="showBL" class="popup-wrapper">
        <BL @back="hidePageBL"></BL>
      </div>
    </transition>
    <transition name="popupBottomMove">
      <div v-if="showXX" class="popup-wrapper">
        <XX @back="hidePageXX"></XX>
      </div>
    </transition>
    <transition name="popupBottomMove">
      <div v-if="showTF" class="popup-wrapper">
        <TF :showFlag="showTF" @back="hidePageTF"></TF>
      </div>
    </transition>
    <transition name="popupBottomMove">
      <div v-if="showXF" class="popup-wrapper">
        <XF :showFlag="showXF" @back="hidePageXF"></XF>
      </div>
    </transition>
    <transition name="popupBottomMove">
      <div v-if="showRZR" class="popup-wrapper">
        <RZR :showFlag="showRZR" @back="hidePageRZR"></RZR>
      </div>
    </transition>
    <transition name="popupBottomMove">
      <div v-if="showDoor" class="popup-wrapper">
        <door :showFlag="showDoor" @back="hidePageDoor"></door>
      </div>
    </transition>
    <!--退出登录-->
    <Modal
      v-model="modalLogin"
      title="退出登录"
      @on-ok="outLogin" width="400px">
      <Input v-model="password" placeholder="请输入密码" type="password"></Input>
    </Modal>
    <!--登录失效-->
    <Modal v-model="modalNone" width="360" :mask-closable="false" :closable="false">
      <p slot="header" style="color:#ed4014;text-align:center">
        <Icon type="md-close-circle" size="18" style="margin-right: 5px"></Icon>
        <span style="letter-spacing: 1px">登录失效</span>
      </p>
      <div style="text-align:center;letter-spacing: 1px">
        <p>登录失效,请联系商家!</p>
      </div>
      <div slot="footer">
      </div>
    </Modal>
    <!--网络状态-->
    <Modal v-model="modalLine" width="360" :mask-closable="false" :closable="false">
      <p slot="header" style="color:#ed4014;text-align:center">
        <Icon type="md-close-circle" size="18" style="margin-right: 5px"></Icon>
        <span style="letter-spacing: 1px">无法连接到网络</span>
      </p>
      <div style="text-align:center;letter-spacing: 1px">
        <p>无法连接到网络,请联系商家!</p>
      </div>
      <div slot="footer">
        <Button type="primary" size="large" long @click="onLine">重新检测</Button>
      </div>
    </Modal>
    <!--广告-->
    <advertising ref="advertising" @hidePage="hidePage"></advertising>
  </div>
</template>

<script>
  import BL from "../components/BL/BL"
  import XX from "../components/XX/XX"
  import TF from "../components/TF/TF"
  import XF from "../components/XF/XF"
  import door from "../components/door/door"
  import RZR from "../components/RZR/RZR"
  import advertising from "../components/advertising/advertising"
  import {getLocalStorage} from "../common/js/localStorage"


  export default {
    name: 'home',
    components: {
      BL,
      XX,
      TF,
      RZR,
      XF,
	  door,
      advertising
    },
    data() {
      return {
        showBL: false,
        showXX: false,
        showTF: false,
        showXF: false,
        showRZR: false,
		showDoor: false,
        showLogo: false,
        modalLogin: false,
        modalNone: false,
        modalLine: false,
        password: null,
        labelObj: {
          title: '',
          content: ''
        },
        hotelName: null,
        hotelPhone: null,
		hotelId: null,
        intervalAdvertising: null,
        showAdveritsing: true
      }
    },
    methods: {
      // 进入全屏的方法
      FullCreeen() {
        let el = document.documentElement;
        let rfs =
          el.requestFullScreen ||
          el.webkitRequestFullScreen ||
          el.mozRequestFullScreen ||
          el.msRequestFullScreen;
        // Element.requestFullscreen() 此方法用于发出异步请求使元素进入全屏模式。
        // 调用此API并不能保证元素一定能够进入全屏模式。如果元素被允许进入全屏幕模式，
        // document对象会收到一个fullscreenchange事件，通知调用者当前元素已经进入全屏模式。如果全屏请求不被许可，则会收到一个fullscreenerror事件。
        if (typeof rfs != "undefined" && rfs) {
          rfs.call(el);
        } else if (typeof window.ActiveXObject != "undefined") {
          let wscript = new ActiveXObject("WScript.Shell");
          if (wscript != null) {
            wscript.SendKeys("{F11}");
          }
        }
      },
      // 退出登录
      outLogin() {
        let nowDate = new Date()
        let phone = getLocalStorage('zs-offline-hotelInfoObj').hotelServicePhone
        let pass = nowDate.getFullYear()+''+(nowDate.getMonth()+1)+''+nowDate.getDate()+''+phone
        if(this.password === pass) {
          this.$router.push('login')
        } else {
          this.$Message.error('密码错误')
        }
      },
      /*打开办理页面*/
      openPageBL() {
        this.showBL = true
        this.showLogo = true
      },
      /*打开线下订房*/
      openPageXX() {
        this.showXX = true
        this.showLogo = true
      },
      /*打开退房*/
      openPageTF() {
        this.showTF = true
        this.showLogo = true
      },
      /*打开续房*/
      openPageXF() {
        this.showXF = true
        this.showLogo = true
      },
      /*打开补录入住人*/
      openPageRZR() {
        this.showRZR = true
        this.showLogo = true
      },
      /*打开大门门禁*/
      openPageDoor() {
        this.showDoor = true
        this.showLogo = true
      },
      /*隐藏办理页面*/
      hidePageBL() {
        this.showBL = false
        setTimeout(() => {
          this.showLogo = false
        }, 400)
      },
      /*隐藏续房页面*/
      hidePageXF() {
        this.showXF = false
        setTimeout(() => {
          this.showLogo = false
        }, 400)
      },
      /*隐藏补录入住人页面*/
      hidePageRZR() {
        this.showRZR = false
        setTimeout(() => {
          this.showLogo = false
        }, 400)
      },
      /*隐藏打开大门页面*/
      hidePageDoor() {
        this.showDoor = false
        setTimeout(() => {
          this.showLogo = false
        }, 400)
      },
      /*隐藏线下页面*/
      hidePageXX() {
        this.showXX = false
        setTimeout(() => {
          this.showLogo = false
        }, 400)
      },
      /*隐藏退房页面*/
      hidePageTF() {
        this.showTF = false
        setTimeout(() => {
          this.showLogo = false
        }, 400)
      },
      //判断网络是否连接
      onLine(){
        let self = this
        let i = new Image()
        i.src = 'https://ss0.bdstatic.com/5aV1bjqh_Q23odCf/static/superman/img/logo_top_ca79a146.png?t=' + Date.parse(new Date())
        i.onload = function() {
          self.modalLine = false
        }
        i.onerror = function() {
          self.modalLine = true
          self.showBL = false
          self.showRZR = false
          self.showXX = false
          self.showRZR = false
          self.showTF = false
          self.showDoor = false
        }
      },
      /*每隔10分钟监听是否能够打开广告*/
      intervalAdvertisingPage() {
        let self = this
        this.intervalAdvertising = setInterval(() => {
          self.ifOpenAdvertising()
        }, 600000)
        /*this.intervalAdvertising = setInterval(() => {
          self.ifOpenAdvertising()
        }, 3000)*/
      },
      /*开启广告*/
      openAdveritsing() {
        this.showAdveritsing = false
        this.intervalAdvertisingPage()
        this.$refs.advertising.play()
      },
      /*判断是否能够打开广告*/
      ifOpenAdvertising() {
        if(this.showBL) {
          return
        }
        if(this.showXF) {
          return
        }
        if(this.showXX) {
          return
        }
        if(this.showRZR) {
          return
        }
        if(this.showTF) {
          return
        }
        if(this.modalLogin) {
          return
        }
        if(this.modalNone) {
          return
        }
        if(this.modalLine) {
          return
        }
        console.log('open')
        if(this.$refs.advertising) {
          this.$refs.advertising.showPage()
        }
      },
      /*打开关闭广告计时*/
      openCloseTime(flag) {
        if(flag) {
          clearInterval(this.intervalAdvertising)
        } else {
          this.intervalAdvertisingPage()
        }
      },
      /*手动关闭广告*/
      hidePage() {
        clearInterval(this.intervalAdvertising)
        this.intervalAdvertisingPage()
      },
    },
    watch: {
      showBL() {
        let token = getLocalStorage('zs-offline-userToken')
        if(typeof token === 'undefined' || !token || token === '') {
          this.modalNone = true
          this.showLogo = true
          this.showBL = false
        }
        this.onLine()
        //this.openCloseTime(this.showPT)
      },
      showXF() {
        let token = getLocalStorage('zs-offline-userToken')
        if(typeof token === 'undefined' || !token || token === '') {
          this.modalNone = true
          this.showLogo = true
          this.showXF = false
        }
        this.onLine()
        //this.openCloseTime(this.showQD)
      },
      showXX() {
        let token = getLocalStorage('zs-offline-userToken')
        if(typeof token === 'undefined' || !token || token === '') {
          this.modalNone = true
          this.showLogo = true
          this.showXX = false
        }
        this.onLine()
        //this.openCloseTime(this.showXX)
      },
      showTF() {
        let token = getLocalStorage('zs-offline-userToken')
        if(typeof token === 'undefined' || !token || token === '') {
          this.modalNone = true
          this.showLogo = true
          this.showTF = false
        }
        this.onLine()
        //this.openCloseTime(this.showTF)
      },
      showRZR() {
        let token = getLocalStorage('zs-offline-userToken')
        if(typeof token === 'undefined' || !token || token === '') {
          this.modalNone = true
          this.showLogo = true
          this.showRZR = false
        }
        this.onLine()
        //this.openCloseTime(this.showTF)
      },
      showDoor() {
        let token = getLocalStorage('zs-offline-userToken')
        if(typeof token === 'undefined' || !token || token === '') {
          this.modalNone = true
          this.showLogo = true
          this.showDoor = false
        }
        this.onLine()
      }
    },
    created() {
      if(getLocalStorage('zs-offline-hotelInfoObj')) {
        this.hotelName = getLocalStorage('zs-offline-hotelInfoObj').hotelName
        this.hotelPhone = getLocalStorage('zs-offline-hotelInfoObj').hotelServicePhone
		this.hotelId = getLocalStorage('zs-offline-hotelInfoObj').hotelId
      } else {
        this.$router.push('/login')
      }
    },
    beforeDestroy() {
      clearInterval(this.intervalAdvertising)
    }
  }
</script>

<style scoped lang="stylus">
  .home-wrapper
    width 100%
    height 100vh
    display flex
    flex-direction column
    overflow auto
    color #222

    .home-logo
      margin 12px 0 0 12px
      display flex
      align-items center
      justify-content flex-start
      color #222
      font-size 16px
      font-weight 500
      z-index 999

      > img
        width auto
        height 64px
        margin-right 12px
    .home-title
      margin-top 60px
      width 100%
      text-align center
      position absolute
      display flex
      align-items center
      justify-content center
      font-size 32px
      letter-spacing 1px
      > p
        border-radius 4px;
        padding 15px 30px
        color #ffffff;
        font-weight 400;
    .home-bg
      top 0;
      left 0;
      width 100%;
      height 100%;
      position fixed;
      z-index -1;
      .home-bg-img
        height 100%;
        position fixed;
        z-index -1
      .home-bg-blur
        width 100%;
        height 100%;
        position fixed;
        background-color rgba(0,0,0,.2)
        z-index 1
    .home-menu
      top 0
      left 0
      width 100%
      height 100%
      position absolute
      display flex
      align-items center
      justify-content center
      text-align center
      font-size 20px;
      font-weight 400;
      letter-spacing 1px
      .home-menu-div
        .home-menu-div-top
          display flex
          align-items center
          justify-content space-around
          > div
            width 50%;
            margin 15px;
            padding 30px 60px;
            border-radius 20px;
            background-color rgba(187,163,127, .9)
            > p
              margin-top 15px;
        .home-menu-div-bottom
          display flex
          align-items center
          justify-content space-between
          > div
            margin 15px;
            padding 30px 60px;
            border-radius 20px;
            background-color rgba(187,163,127, .9)
            > p
              margin-top 15px;
            > img
              width 60px;
              height 60px;
    .home-menu-div-top-img
      display flex
      align-items center
      justify-content space-around
      > img
        height 60px;
        margin-right 10px
    .home-footer
      bottom 24px
      right 24px
      width 100%
      position absolute
      display flex
      align-items center
      justify-content flex-end
      font-size 14px
      color #222
    .home-phone
      top 24px
      right 24px
      position absolute
      font-size 18px
      font-weight 600
      color #fff
  .home-menu-icon
    display inline-block
    width 32px !important
    height 32px !important
    margin-right 12px

  .home-menu-left
    margin-left 38px

  .home-menu-top
    margin-top 38px

  .home-menu-bottom
    margin-bottom 38px

  .home-menu-right
    margin-right 38px
  .popup-wrapper
    top 0
    left 0
    width 100%
    height 100%
    position fixed
    z-index 9
  .popupBottomMove-enter-active, .popupBottomMove-leave-active
    transition all .4s
  .popupBottomMove-enter, .popupBottomMove-leave-to
    transform translateY(100%)
</style>

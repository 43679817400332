import {getLocalStorage} from "./localStorage";

export const baseUrl = {
  dev: 'https://v3-app-api.aihotel.top',
  pro: 'https://v3-app-api.aihotel.top',
  ws: 'wss://socket.aihotel.top/ws/auth',
  wss: 'wss://socket.aihotel.top/ws'
}
export const uploadUrl = {
  dev: 'https://v3-app-api.aihotel.top',
  pro: 'https://v3-app-api.aihotel.top'
}

export const getImgUrl = (val) => {
  let str = new RegExp("http");
  let imgN = ''
  if(str.test(val)) {
    imgN = val
  } else {
    imgN = getUploadUrl.pro + '/' + val
  }
  return imgN
}

export const systemCFG = {
  // 消息框消失时间
  messageDuration: 3000,
  // 标题
  title:"无疆智慧订房",
  // token
  tokenKey:"zs-bg-userToken",
  // tokenUserId
  tokenUserIdKey: "zs-bg-userId",
  tokenUserVO: "zs-bg-userVO",
  // 用户失效code
  userFailureCode: 401,
  // 请求成功code
  successCode:200
};

export function getToken() {
  let token = getLocalStorage('zs-bg-userToken')
  if(token && typeof(token) !== 'undefined') {
    return token
  } else {
    return  false
  }
}

export function getUserId() {
  let userId = getLocalStorage('zs-bg-userId')
  if(userId && typeof(userId) !== 'undefined') {
    return userId
  } else {
    return  false
  }
}

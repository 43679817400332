<template>
  <div>
    <transition name="popupBottomMove">
      <div class="popup-bottom" ref="popup">
        <!--页面顶部-->
        <div class="popup-bottom-title">
          <p @click="back" class="popup-bottom-title-button">
            <Button shape="circle" type="primary" size="large" :icon="'ios-arrow-down'">关闭</Button>
          </p>
          <div class="popup-bottom-title-steps">
            <div style="width: 80%">
              <Steps :current="stepIndex">
                <Step :title="item.title" :content="item.content" v-for="(item,index) in steps" :key="item.id"></Step>
              </Steps>
            </div>
          </div>
          <div class="popup-bottom-time">{{ backTime }}s</div>
        </div>
        <!--页面内容-->
        <div class="popup-bottom-body" ref="popupBottomWrapper">
          <div>
            <Carousel v-model="stepIndex" dots="none" arrow="never">
              <!--选择时间-->
              <CarouselItem>
                <div class="time-wrapper">
                  <loadingPage v-show="loadingInit"></loadingPage>
                  <div v-show="!loadingInit" class="time-left">
                    <h4>计费模式选择</h4>
                    <div class="time-action">
                      <div style="width: 100%;padding-right: 15px">
                        <template v-if="typeTime === 0">
                          <div class="time-action-item" :class="timeType === 1 ? 'time-action-item-yes' : ''"
                               @click="actionType(1)">全日房
                          </div>
                          <div style="height: 48px"></div>
                          <div class="time-action-item" :class="timeType === 2 ? 'time-action-item-yes' : ''"
                               @click="actionType(2)">钟点房
                          </div>
                        </template>
                        <template v-if="typeTime === 1">
                          <div class="time-action-item" :class="timeType === 1 ? 'time-action-item-yes' : ''"
                               @click="actionType(1)">全日房
                          </div>
                        </template>
                        <template v-if="typeTime === 2">
                          <div class="time-action-item" :class="timeType === 2 ? 'time-action-item-yes' : ''"
                               @click="actionType(2)">钟点房
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div v-show="!loadingInit" style="width: 80%">
                    <template v-if="flagObj.time">
                      <dayModel v-if="timeType === 1" @selectTime="selectTime"
                                :buttonLoading="buttonLoading"></dayModel>
                      <timeModel v-if="timeType === 2" @sure="getTimeData"></timeModel>
                      <transition name="moveTop">
                        <div v-if="flagObj.timeButton" class="time-button">
                          <div style="width: 40%">
                            <Button type="primary" size="large" long @click="sureTime" :loading="buttonLoading">确定
                            </Button>
                          </div>
                        </div>
                      </transition>
                    </template>
                  </div>
                </div>
              </CarouselItem>
              <!--选择房型房间-->
              <CarouselItem>
                <div v-if="stepIndex === 1" style="position: relative">
                  <div v-if="typeArr.length > 1" class="type-item-pageAction-left" @click="pageLeft"><Icon type="md-arrow-dropleft-circle" /></div>
                  <div v-if="typeArr.length > 1" class="type-item-pageAction-right" @click="pageRight"><Icon type="md-arrow-dropright-circle" /></div>
                  <template v-if="timeType === 1">
                    <div class="type-item-wrapper" ref="typeWrapper">
                      <Card v-for="(item, index) in typeArr[pageIndex]" :key="item.id" style="width: 420px;margin-bottom: 2%">
                        <div @click="showRoom(item)" class="type-item">
                          <div class="type-item-left">
                            <img :src="item.typeCover | getImg">
                          </div>
                          <div class="type-item-right">
                            <h4>{{ item.typeName }}</h4>
                            <div>
                              <Tag color="orange" v-for="i in item.typeLabel" :key="i">{{ i }}</Tag>
                            </div>
                            <div class="price-day">
                              <div><h5>￥{{ item.dayPrice / 100 }}</h5>/天</div>
                              <div class="price-day-label">订</div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </template>
                  <template v-if="timeType === 2">
                    <div class="type-item-wrapper" ref="typeWrapper">
                      <Card v-for="(item, index) in typeArr[pageIndex]" :key="item.id" style="width: 46%;margin-bottom: 2%">
                        <div @click="showRoom(item)" class="type-item">
                          <div class="type-item-left">
                            <img :src="item.typeCover | getImg">
                          </div>
                          <div class="type-item-right">
                            <h4>{{ item.typeName }}</h4>
                            <div>
                              <Tag color="orange" v-for="i in item.typeLabel" :key="i">{{ i }}</Tag>
                            </div>
                            <div class="price-day">
                              <div><h5>￥{{ item.dayPrice / 100 }}</h5>/天</div>
                              <div class="price-day-label">订</div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </template>
                </div>
              </CarouselItem>
              <!--扫描二维码-->
              <CarouselItem>
                <search-obj-w-s v-if="stepIndex === 2" @sendUseObj="gerUserObj" @back="back"></search-obj-w-s>
              </CarouselItem>
              <!--核验人证信息-->
              <CarouselItem>
                <id-card v-if="stepIndex === 3" @sure="sureIdCard" @again="backTime = 300" @wzOpen="wzOpen"
                         @wzHide="wzHide"></id-card>
              </CarouselItem>
              <!--确认订单 付款-->
              <CarouselItem>
                <div v-if="stepIndex === 4" style="padding: 24px 168px">
                  <List border>
                    <div slot="header">
                      <h5 style="font-size: 18px;text-align: center">请确认订单信息</h5>
                    </div>
                    <ListItem><p class="order-item">房型<span>{{ orderObj.roomTypeDTO.typeName }}</span></p></ListItem>
                    <ListItem><p class="order-item">房间<span>{{ orderObj.roomInfoDTO.name }}</span></p></ListItem>
                    <ListItem><p class="order-item">入住时间<span>{{ orderObj.startTime }}</span></p></ListItem>
                    <ListItem><p class="order-item">离店时间<span>{{ orderObj.endTime }}</span></p></ListItem>
                    <ListItem><p class="order-item">房费<span
                        style="color: #ed4014;font-size: 18px;font-weight: 600">￥{{ orderObj.roomPrice / 100 | goodOverFlow }}</span>
                    </p></ListItem>
                    <ListItem><p class="order-item">押金<span
                        style="color: #ed4014;font-size: 18px;font-weight: 600">￥{{ orderObj.depositMoney / 100 | goodOverFlow }}</span>
                    </p></ListItem>
                    <ListItem><p class="order-item">总计<span
                        style="color: #ed4014;font-size: 18px;font-weight: 600">￥{{ orderObj.allPrice / 100 | goodOverFlow }}</span>
                    </p></ListItem>
                    <ListItem><p class="order-item">提示<span style="color: #ed4014;font-size: 16px;font-weight: 600">押金会在房间打扫完成后原路退还</span>
                    </p></ListItem>
                  </List>
                  <div style="display: flex;align-items: center;justify-content: center;margin-top: 60px">
                    <Button type="primary" long size="large"
                            style="margin-right: 20%;background-color: #2d8cf0;border-color: #2d8cf0" @click="aliPay"
                            :loading="buttonLoading">
                      支付宝支付
                    </Button>
                    <Button type="success" long size="large" @click="wxPay" :loading="buttonLoading">微信支付</Button>
                  </div>
                </div>
              </CarouselItem>
              <!--操作流程指导-->
              <CarouselItem>
                <guide v-if="stepIndex === 5" :roomNum="roomObj ? roomObj.name : '202'"></guide>
              </CarouselItem>
            </Carousel>
          </div>
        </div>
      </div>
    </transition>
    <!--微信支付-->
    <Modal v-model="flagObj.wx" title="微信支付" :mask-closable="false" @on-cancel="closeWxPay">
      <canvas ref="qrccodeCanvas" style="display: none"></canvas>
      <div class="pay-img">
        <div>
          <div class="pay-img-icon-wrapper">
            <img class="pay-img-icon" src="../../assets/wxpay.svg">
          </div>
          <img class="pay-img-qr" :src="wxPayImg" style="height: 300px">
        </div>
      </div>
      <div slot="footer">
        <p style="text-align: center;font-size: 16px;color: #515a6e;font-weight: 600">若已支付，请点击下面"我已支付"按钮</p>
        <Button type="success" style="margin-top: 10px" long size="large" @click="paySuccess">我已支付</Button>
      </div>
    </Modal>
    <!--支付宝支付-->
    <Modal v-model="flagObj.al" title="支付宝支付" :mask-closable="false" @on-cancel="closeAliPay">
      <canvas ref="qrccodeCanvas" style="display: none"></canvas>
      <div class="pay-img">
        <div>
          <canvas ref="qrccodeCanvas" style="display: none"></canvas>
          <div class="pay-img-icon-wrapper">
            <img class="pay-img-icon" src="../../assets/alipay.svg">
          </div>
          <img class="pay-img-qr" :src="aliPayImg" style="height: 300px">
          <p>打开支付宝扫一扫付款</p>
        </div>
      </div>
      <div slot="footer">
        <p style="text-align: center;font-size: 16px;color: #515a6e;font-weight: 600">若已支付，请点击下面"我已支付"按钮</p>
        <Button type="info" style="margin-top: 10px" long size="large" @click="paySuccess">我已支付</Button>
      </div>
    </Modal>
    <!--未支付订单-->
    <Modal v-model="flagObj.waitPay" width="540" title="未完成订单" :closable="false" :mask-closable="false">
      <List v-if="waitPayOrder" border size="large">
        <ListItem>
          <div style="display: flex;align-items: center;justify-content: space-between;width: 100%">
            <div>订单信息</div>
            <div v-if="waitPayOrderType === 1">{{ waitPayOrder.masterInfo }}</div>
            <div v-if="waitPayOrderType === 2">{{ waitPayOrder.orderInfo }}</div>
          </div>
        </ListItem>
        <ListItem>
          <div style="display: flex;align-items: center;justify-content: space-between;width: 100%">
            <div v-if="waitPayOrderType === 1">入住时间</div>
            <div v-if="waitPayOrderType === 2">下单时间</div>
            <div v-if="waitPayOrderType === 1">{{ waitPayOrder.orderInTime }}</div>
            <div v-if="waitPayOrderType === 2">{{ waitPayOrder.createdTime }}</div>
          </div>
        </ListItem>
        <ListItem>
          <div style="display: flex;align-items: center;justify-content: space-between;width: 100%">
            <div>离店时间</div>
            <div v-if="waitPayOrderType === 1">{{ waitPayOrder.orderOutTime }}</div>
            <div v-if="waitPayOrderType === 2">{{ waitPayOrder.endTime }}</div>
          </div>
        </ListItem>
        <ListItem>
          <div style="display: flex;align-items: center;justify-content: space-between;width: 100%">
            <div>订单金额</div>
            <div v-if="waitPayOrderType === 1" style="color: #ed4014;font-size: 18px;font-weight: 600">
              ￥{{ (waitPayOrder.allAmount + waitPayOrder.allDeposit) / 100 }}
            </div>
            <div v-if="waitPayOrderType === 2" style="color: #ed4014;font-size: 18px;font-weight: 600">
              ￥{{ waitPayOrder.allMoney / 100 }}
            </div>
          </div>
        </ListItem>
      </List>
      <div slot="footer" style="display: flex;align-items: center;justify-content: space-around">
        <Button size="large" :loading="buttonLoading" @click="cancelWaitPayOrder">取消支付</Button>
        <Button type="success" size="large" :loading="buttonLoading" @click="waitPayOrderForWx">微信支付</Button>
        <Button type="info" size="large" :loading="buttonLoading" @click="waitPayOrderForAli">支付宝支付</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import idCard from "../idCard/idCard"
import calendar from "../calendar/calendar"
import timeModel from "../timeModel/timeModel"
import dayModel from "../dayModel/dayModel"
import Phone from "../phone/phone"
import wxCode from "../wxCode/wxCode"
import loadingPage from "../loadingPage/loadingPage"
import guide from "../guide/guide"
import {getLocalStorage} from "../../common/js/localStorage"
import {formatDate} from "../../common/js/formatTime"
import searchObjWS from "../searchObjWS/searchObjWS"
import {calculationDateForDay} from "../../common/js/calculationDate"
import {calculationDateForTime} from "../../common/js/calculationTime"
import {baseUrl} from "../../common/js/request"
import QRCode from 'qrcode'
import axios from "axios"

export default {
  name: "XX",
  components: {
    Phone,
    idCard,
    calendar,
    timeModel,
    dayModel,
    wxCode,
    loadingPage,
    guide,
    searchObjWS
  },
  data() {
    return {
      hotelDetail: null,
      hotelObj: null,
      steps: [
        {
          id: 0,
          title: '进行中',
          content: '选择时间'
        },
        {
          id: 1,
          title: '待进行',
          content: '选择房间'
        },
        {
          id: 2,
          title: '待进行',
          content: '扫描二维码'
        },
        {
          id: 3,
          title: '待进行',
          content: '人证核验'
        },
        {
          id: 4,
          title: '待进行',
          content: '选择付款'
        },
        {
          id: 5,
          title: '待进行',
          content: '开门指引'
        }
      ],
      stepIndex: 0,
      userImg: '',
      dateObj: {
        startTime: '',
        endTime: '',
        areaTime: null
      },
      timeType: 1,
      flagObj: {
        time: false,
        timeButton: false,
        type: false,
        wx: false,
        al: false,
        waitPay: false
      },
      typeArrow: "never",
      typeArr: [],
      typeObj: null,
      roomObj: null,
      idCardArr: [],
      orderObj: {
        id: null,
        deposit: null,
        price: null,
        number: null
      },
      wxPayImg: '',
      backTime: 300,
      intervalTime: null,
      aliPayImg: null,
      loadingInit: true,
      typeTime: 0,
      buttonLoading: false,
      userId: 0,
      userOpenId: 0,
      phone: null,
      waitPayOrder: null,
      waitPayOrderType: 0,
      cancelId: 0,
      pageIndex: 0,
      socket: null,
      wsHeart: null
    }
  },
  methods: {
    /*返回首页*/
    back() {
      this.stepIndex = 0
      this.steps[this.stepIndex].title = "进行中"
      this.steps[this.stepIndex + 1].title = "待进行"
      this.flagObj.timeButton = false
      this.idCardArr = []
      clearInterval(this.intervalTime)
      this.$emit('back')
    },
    /*点击忘证入住*/
    wzOpen() {
      this.backTime = 300
    },
    /*关闭忘证入住*/
    wzHide() {
      this.backTime = 300
    },
    /*选择时租 日租*/
    actionType(val) {
      if (val === 1) {
        this.timeType = 1
        this.flagObj.timeButton = false
      } else {
        this.timeType = 2
        this.flagObj.timeButton = false
      }
    },
    /*确定选择时间 时租*/
    sureTime() {
      this.getHotelData()
    },
    /*获取选择的时间 时租*/
    getTimeData(val) {
      this.dateObj.startTime = formatDate(new Date())
      this.dateObj.endTime = val
      this.dateObj.areaTime = 1
      this.flagObj.timeButton = true
    },
    /*获取选择的时间 日租*/
    selectTime(startTime, endTime, num) {
      this.dateObj.startTime = startTime
      this.dateObj.endTime = endTime
      this.dateObj.areaTime = num
      this.getHotelData()
    },
    /*展示房间*/
    showRoom(val) {
      if (!val.roomCount) {
        this.$Message.warning("该房型已经满房了")
      } else {
        this.typeObj = val
        if(val.roomInfoVOList.length < 1) {
          this.$Message.error("该房型已经满房了")
          return
        }
        this.sureRoom(val.roomInfoVOList[0])
      }
    },
    /*确定选择房间*/
    sureRoom(val) {
      this.backTime = 300
      this.roomObj = val
      this.stepIndex = 2
      this.steps[this.stepIndex].title = "进行中"
      this.steps[this.stepIndex - 1].title = "已完成"
    },
    /*确定身份证核验*/
    sureIdCard(val) {
      this.idCardArr = val
      this.backTime = 300
      this.stepIndex = 4
      this.steps[this.stepIndex].title = "进行中"
      this.steps[this.stepIndex - 1].title = "已完成"
      this.bindingGuest()
      this.createOrderSure()
    },
    /*添加入住人*/
    bindingGuest() {
      this.$http.post('/users/add/guest/list', this.idCardArr).then(res => {
      })
    },
    /*获取userObj*/
    gerUserObj(user) {
      this.userId = user.userId
      this.userOpenId = user.userOpenId
      this.phone = user.phone
      /*this.selectWaitPayOrder()*/
      this.stepIndex = 3
      this.steps[this.stepIndex].title = "进行中"
      this.steps[this.stepIndex - 1].title = "已完成"
      this.createOrder()
    },
    /*生成订单*/
    createOrder() {
      let self = this
      self.waitPayOrderType = 0
      /*全日房*/
      if (self.timeType === 1) {
        let obj = {
          date: calculationDateForDay(new Date(this.dateObj.startTime.replace(/-/g, '/')), new Date(this.dateObj.endTime.replace(/-/g, '/'))),
          hotelId: this.hotelId,
          isPreordain: 0,
          orderType: 2,
          orderTypeName: '线下',
          roomInfoId: this.roomObj.roomInfoId,
          roomTypeId: this.typeObj.roomTypeId,
          startTime: formatDate(new Date(this.dateObj.startTime.replace(/-/g, '/'))),
          endTime: formatDate(new Date(this.dateObj.endTime.replace(/-/g, '/'))),
          userId: this.userId
        }
        this.$http.post('/order/create/day', obj).then(res => {
          self.orderObj = res.result
          let roomPrice = 0
          self.orderObj.priceDTOList.forEach((item) => {
            roomPrice += item.dayPrice
          })
          self.orderObj.roomPrice = roomPrice
          self.orderObj.allPrice = roomPrice + self.orderObj.depositMoney
        })
      }
      /*时租房*/
      if (self.timeType === 2) {
        let obj = {
          timeDates: calculationDateForTime(new Date(this.dateObj.startTime.replace(/-/g, '/')), new Date(this.dateObj.endTime.replace(/-/g, '/'))),
          hotelId: this.hotelId,
          orderType: 2,
          orderTypeName: '线下',
          roomInfoId: this.roomObj.roomInfoId,
          roomTypeId: this.typeObj.roomTypeId,
          startTime: formatDate(new Date(this.dateObj.startTime.replace(/-/g, '/'))),
          endTime: formatDate(new Date(this.dateObj.endTime.replace(/-/g, '/'))),
          userId: this.userId
        }
        this.$http.post('/order/create/time', obj).then(res => {
          self.orderObj = res.result
          let roomPrice = 0
          self.orderObj.priceDTOList.forEach((item) => {
            roomPrice += item.hourPrice + item.hourService
          })
          self.orderObj.roomPrice = roomPrice
          self.orderObj.allPrice = roomPrice + self.orderObj.depositMoney
        })
      }
    },
    /*生成支付订单*/
    createOrderSure() {
      console.log(this.idCardArr)
      let self = this
      let list = []
      this.buttonLoading = true
      /*全日房*/
      if (this.timeType === 1) {
        this.idCardArr.forEach((item) => {
          list.push(item.idCardDto)
        })
        let obj = {
          date: calculationDateForDay(new Date(this.dateObj.startTime.replace(/-/g, '/')), new Date(this.dateObj.endTime.replace(/-/g, '/'))),
          depositMoney: this.orderObj.depositMoney,
          endTime: this.orderObj.endTime,
          hotelId: this.orderObj.hotelId,
          idCardDtoList: list,
          isPreordain: this.orderObj.isPreordain,
          joinStatus: 0,
          openRoomType: this.hotelObj.hotelOpenDoorType,
          orderType: this.orderObj.orderType,
          orderTypeName: this.orderObj.orderTypeName,
          remark: this.orderObj.remark,
          roomInfoId: this.orderObj.roomInfoId,
          roomTypeId: this.orderObj.roomTypeId,
          startTime: this.orderObj.startTime,
          userId: this.orderObj.userId,
          userPhone: this.phone,
          username: this.idCardArr[0].name
        }
        this.$http.post('/order/submit/day', obj).then(res => {
          if(res.status == 200) {
            self.cancelId = res.result
            self.buttonLoading = false
          }
        })
      } else {
        this.idCardArr.forEach((item) => {
          list.push(item.idCardDto)
        })
        let obj = {
          timeDates: calculationDateForTime(new Date(this.dateObj.startTime.replace(/-/g, '/')), new Date(this.dateObj.endTime.replace(/-/g, '/'))),
          depositMoney: this.orderObj.depositMoney,
          endTime: this.orderObj.endTime,
          hotelId: this.orderObj.hotelId,
          idCardDtoList: list,
          isPreordain: this.orderObj.isPreordain,
          joinStatus: 0,
          openRoomType: this.hotelObj.hotelOpenDoorType,
          orderType: this.orderObj.orderType,
          orderTypeName: this.orderObj.orderTypeName,
          remark: this.orderObj.remark,
          roomInfoId: this.orderObj.roomInfoId,
          roomTypeId: this.orderObj.roomTypeId,
          startTime: this.orderObj.startTime,
          userId: this.orderObj.userId,
          userPhone: this.phone,
          username: this.idCardArr[0].name
        }
        this.$http.post('/order/submit/time', obj).then(res => {
          if(res.status == 200) {
            self.cancelId = res.result
            self.buttonLoading = false
          }
        })
      }
    },
    /*微信支付*/
    wxPay() {
      this.backTime = 300
      let self = this
      if (self.wxPayImg) {
        self.flagObj.wx = true
      } else {
        this.buttonLoading = true
        let obj = {
          orderId: this.cancelId,
          type: '1',
          hotelId: this.hotelId
        }
        this.$http.postFromData(baseUrl.dev + '/wx/mini/create/unlimit/wx/qrcode', {
          path: 'pages/codePay/codePay',
          scene: JSON.stringify(obj)
        }).then(re => {
          self.flagObj.wx = true
          self.buttonLoading = false
          self.wxPayImg = re
          self.createWS()
        })
      }
    },
    /*支付宝支付*/
    aliPay() {
      this.backTime = 300
      let self = this
      if (self.aliPayImg) {
        self.flagObj.al = true
      } else {
        this.buttonLoading = true
        self.$http.post('/pay/ali/prepay/new/order?orderId=' + this.cancelId + '&openId=' + this.userOpenId).then(re => {
          self.canvasDiv = self.$refs.qrccodeCanvas
          QRCode.toCanvas(self.canvasDiv, re.result, {
            width: 300
          }, (error) => {
            self.flagObj.al = true
            self.buttonLoading = false
            if (error) {
              self.$Message.error('二维码生成失败')
            } else {
              self.createWS()
              let myCanvas = self.$refs.qrccodeCanvas
              self.aliPayImg = myCanvas.toDataURL('image/png')
            }
          })
        })
      }
    },
    /*关闭微信支付*/
    closeWxPay() {
      if (this.waitPayOrderType !== 0) {
        this.flagObj.waitPay = true
      } else {
        this.backTime = 300
        this.flagObj.wx = false
        // this.$http.get('/order/cancel/'+this.cancelId).then(res => {})
      }
    },
    /*关闭支付宝支付*/
    closeAliPay() {
      if (this.waitPayOrderType !== 0) {
        this.flagObj.waitPay = true
      } else {
        this.backTime = 300
        this.flagObj.al = false
        // this.$http.get('/order/cancel/'+this.cancelId).then(res => {})
      }
    },
    /*获取酒店房型数据*/
    getHotelData() {
      this.buttonLoading = true
      let self = this
      let obj = {
        hotelId: this.hotelId,
        day: this.dateObj.areaTime,
        isReservation: 0,
        startTime: formatDate(new Date(this.dateObj.startTime.replace(/-/g, '/'))),
        endTime: formatDate(new Date(this.dateObj.endTime.replace(/-/g, '/')))
      }
      this.$http.get('/home/hotel/room/type/info', {
        params: obj
      }).then(response => {
        self.buttonLoading = false
        let closeObj = {
          hotelId: self.hotelId,
          startTime: formatDate(new Date(this.dateObj.startTime.replace(/-/g, '/'))),
          endTime: formatDate(new Date(this.dateObj.endTime.replace(/-/g, '/')))
        }
        self.$http.get('/home/room/close', {
          params: closeObj
        }).then(ree => {
          let typeNum = 0
          let typeArrEach = []
          response.result.forEach((item) => {
            ree.result.forEach((it) => {
              item.roomInfoVOList.forEach((i,index) => {
                if(it.roomId === i.roomInfoId) {
                  item.roomInfoVOList.splice(index,1)
                }
              })
            })
          })
          response.result.forEach((item) => {
            if(item.roomInfoVOList.length !== 0) {
              typeNum += item.roomInfoVOList.length
              item.typeLabel = item.typeLabel.split(',')
              item.roomCount = item.roomInfoVOList.length
              typeArrEach.push(item)
            }
          })
          console.log(typeNum)
          if(typeNum === 0) {
            self.$Notice.warning({
              title: '酒店已满房',
              desc: '酒店已经满房了'
            })
            return
          }
          if (typeNum > 6) {
            self.typeArrow = "always"
          }
          let arr = []
          typeArrEach.forEach((item, index) => {
            arr.push(item)
            let num = (index + 1) / 6
            if (/(^[1-9]\d*$)/.test(num)) {
              self.typeArr.push(arr)
              arr = []
            }
            if (index === (typeArrEach.length - 1)) {
              self.typeArr.push(arr)
            }
          })
          self.backTime = 300
          self.steps[0].title = "已完成"
          self.steps[1].title = "进行中"
          self.stepIndex = 1
        })
      })
    },
    /*页面倒计时*/
    pageOutTime() {
      this.intervalTime = setInterval(() => {
        this.backTime--
        if (this.backTime <= 0) {
          clearInterval(this.intervalTime)
          this.stepIndex = 0
          this.steps[0].title = "进行中"
          this.steps[1].title = "待进行"
          this.steps[2].title = "待进行"
          this.steps[3].title = "待进行"
          this.steps[4].title = "待进行"
          this.steps[5].title = "待进行"
          this.flagObj.timeButton = false
          this.idCardArr = []
          this.flagObj.wx = false
          this.flagObj.al = false
          this.$emit('back')
        }
      }, 1000)
    },
    /*打开入住指引*/
    goGuide() {
      this.backTime = 60
      this.stepIndex = 5
      this.steps[this.stepIndex - 1].title = "已完成"
      this.uploadHX()
      this.bindUser()
    },
    /*查询未支付订单*/
    selectWaitPayOrder() {
      let self = this
      this.$http.get('/order/query/user/pay?userId=' + this.userId).then(res => {
        if (res.result.length > 0) {
          self.flagObj.waitPay = true
          self.waitPayOrderType = res.result[0].newStatus
          if (res.result[0].newStatus === 1) {
            self.$http.get('/order/master/description/' + res.result[0].orderId).then(ress => {
              self.waitPayOrder = ress.result
            })
          }
          if (res.result[0].newStatus === 2) {
            self.$http.get('/order/get/continue/des?orderId=' + res.result[0].orderId).then(ress => {
              self.waitPayOrder = ress.result
            })
          }
        } else {
          self.stepIndex = 3
          self.steps[self.stepIndex].title = "进行中"
          self.steps[self.stepIndex - 1].title = "已完成"
          self.createOrder()
        }
      })
    },
    /*取消待支付订单*/
    cancelWaitPayOrder() {
      let self = this
      self.buttonLoading = true
      if (this.waitPayOrderType === 1) {
        this.$http.get('/order/cancel/' + this.waitPayOrder.masterId).then(res => {
          self.flagObj.waitPay = false
          self.buttonLoading = false
          self.wxPayImg = ''
          self.aliPayImg = ''
          self.stepIndex = 3
          self.steps[self.stepIndex].title = "进行中"
          self.steps[self.stepIndex - 1].title = "已完成"
          self.createOrder()
        })
      }
      if (this.waitPayOrderType === 2) {
        this.$http.post('/order/continue/room/cancel?continueId=' + this.waitPayOrder.id).then(res => {
          self.flagObj.waitPay = false
          self.buttonLoading = false
          self.wxPayImg = ''
          self.aliPayImg = ''
          self.stepIndex = 3
          self.steps[self.stepIndex].title = "进行中"
          self.steps[self.stepIndex - 1].title = "已完成"
          self.createOrder()
        })
      }
    },
    /*待支付 微信支付*/
    waitPayOrderForWx() {
      let id = 0
      let obj = {}
      if (this.waitPayOrderType === 1) {
        id = this.waitPayOrder.masterId
        obj = {
          path: '/pages/codePay/codePay?orderMasterId=' + id + '&type=3&hotelId=' + this.hotelId,
          width: 480
        }
      }
      if (this.waitPayOrderType === 2) {
        id = this.waitPayOrder.id
        obj = {
          path: '/pages/codePay/codePay?orderMasterId=' + id + '&type=2&id=' + this.waitPayOrder.masterId + '&hotelId=' + this.hotelId,
          width: 480
        }
      }
      let self = this
      self.buttonLoading = true
      axios.get(baseUrl.dev + '/wx/mini/create/wx/qrcode', {
        params: obj
      }).then(re => {
        self.flagObj.wx = true
        self.buttonLoading = false
        self.flagObj.waitPay = false
        self.wxPayImg = re.data
        self.createWS()
      })
    },
    /*待支付 支付宝支付*/
    waitPayOrderForAli() {
      let id = 0
      if (this.waitPayOrderType === 1) {
        id = this.waitPayOrder.masterId
      }
      if (this.waitPayOrderType === 2) {
        id = this.waitPayOrder.id
      }
      let self = this
      self.buttonLoading = true
      this.$http.post('/pay/alipay/create/order/launch?orderMasterId=' + id).then(re => {
        self.canvasDiv = self.$refs.qrccodeCanvas
        QRCode.toCanvas(self.canvasDiv, re.result, {
          width: 300
        }, (error) => {
          self.flagObj.al = true
          self.buttonLoading = false
          self.flagObj.waitPay = false
          if (error) {
            self.$Message.error('二维码生成失败')
          } else {
            self.createWS()
            let myCanvas = self.$refs.qrccodeCanvas
            self.aliPayImg = myCanvas.toDataURL('image/png')
          }
        })
      })
    },
    //绑定入住人
    bindUser() {
      let list = []
      this.idCardArr.forEach((item) => {
        list.push(item.idCardDto)
      })
      let obj = {
        cardDtoList: list,
        masterId: this.cancelId
      }
      this.$http.post('/order/bind/user/guest', obj).then(res => {
      })
    },
    /*上传航信*/
    uploadHX() {
      let list = []
      this.idCardArr.forEach((item) => {
        let obj = {
          name: item.idCardDto.name,
          birthDay: item.idCardDto.birthDay,
          idNo: item.idCardDto.idNo,
          idCoverBase64: item.idCardDto.cover,
          nowCoverBase64: item.sitePhoto,
          address: item.idCardDto.address,
          validDate: item.idCardDto.validDate,
          issueAgency: item.idCardDto.issueAgency,
          nation: item.idCardDto.nation,
          sex: item.idCardDto.sex,
          type: item.idCardDto.type,
          city: item.idCardDto.city
        }
        list.push(obj)
      })
      let paramObj = {
        orderMasterId: this.cancelId,
        infoList: list,
        hotelId: this.hotelId,
        roomName: this.roomObj.name
      }
      this.$http.postV('/order/handle/hangxin/in', paramObj).then(res => {})
    },
    /*上一页*/
    pageLeft() {
      if(this.pageIndex > 0) {
        this.pageIndex--
      }
    },
    /*下一页*/
    pageRight() {
      if(this.pageIndex < this.typeArr.length - 1) {
        this.pageIndex++
      }
    },
    //  支付成功
    paySuccess() {
      if(this.cancelId) {
        let self = this
        this.$http.get('/order/get/master/des?orderId='+this.cancelId).then(res => {
          if(res.result.orderMaster.orderStatus > 1) {
            self.goGuide()
            self.flagObj.al = false
            self.flagObj.wx = false
          } else {
            self.$Message.warning('请支付')
          }
        })
      }
    },
    /*建立websocket连接*/
    createWS() {
      if(typeof(WebSocket) === "undefined"){
        alert("您的浏览器不支持socket")
      }else{
        // 实例化socket
        this.socket = new WebSocket(baseUrl.wss)
        // 监听socket连接
        this.socket.onopen = this.WSOpen
        // 监听socket错误信息
        this.socket.onerror = this.WSError
        // 监听socket消息
        this.socket.onmessage = this.WSMessage
        this.socket.onclose = () => {
          console.log('websocket关闭了')
        }
      }
    },
    //ws注册
    registerWS() {
      let obj = {
        id: this.hotelId,
        sendDevice: "PC_CLIENT",
        acceptDevice: "MA_CLIENT",
        cmd: "ws_register",
        data: ""
      }
      this.socket.send(JSON.stringify(obj))
      this.sendHeartWS()
    },
    //ws心跳
    sendHeartWS() {
      this.wsHeart = setInterval(() => {
        let obj = {
          id: this.hotelId,
          sendDevice: "PC_CLIENT",
          acceptDevice: "MA_CLIENT",
          cmd: "ws_heart",
          data: ""
        }
        this.socket.send(JSON.stringify(obj))
      }, 30000)
    },
    // 监听socket消息
    WSMessage(res) {
      console.log('监听消息')
      const message = JSON.parse(res.data)
      if(message.cmd === 'order_pay_success') {
        this.closeWS()
        this.flagObj.al = false
        this.flagObj.wx = false
        this.goGuide()
      }
    },
    /* 监听socket连接*/
    WSOpen() {
      console.log('连接成功')
      this.registerWS()
    },
    //关闭链接
    closeWS() {
      let obj = {
        id: this.hotelId,
        sendDevice: "PC_CLIENT",
        acceptDevice: "MA_CLIENT",
        cmd: "pc_close_ma",
        data: ""
      }
      if(this.socket) {
        this.socket.close()
        this.socket.send(JSON.stringify(obj))
      }
      clearInterval(this.wsHeart)
      console.log('关闭ws')
    },
    // 监听socket错误信息
    WSError() {
      console.log('连接失败')
    }
  },
  created() {
    let self = this
    setTimeout(() => {
      self.hotelObj = getLocalStorage('zs-offline-hotelDetailsObj')
      self.hotelId = getLocalStorage("zs-offline-hotelId")
      self.$http.get('/home/hotel/des/' + self.hotelId).then(res => {
        let dayRoom = res.result.hotelDayRoomStatus
        let hourRoom = res.result.hotelHourRoomStatus
        if (dayRoom === 0) {
          self.typeTime = 2
        }
        if (hourRoom === 0) {
          self.typeTime = 1
        }
        if (self.typeTime === 0 || self.typeTime === 1) {
          self.timeType = 1
        } else {
          self.timeType = 2
        }
        self.timeType = 1
        self.backTime = 300
        self.pageOutTime()
        setTimeout(() => {
          self.loadingInit = false
        }, 500)
      })
    }, 200)
    /*延迟2秒显示时间日历*/
    setTimeout(() => {
      self.flagObj.time = true
    }, 2000)
  },
  filters: {
    goodOverFlow(val) {
      let v = parseFloat(val)
      return v.toFixed(2)
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalTime)
    this.closeWS()
    this.loadingInit = true
  }
}
</script>

<style scoped lang="stylus">
.popup-bottom
  bottom 0
  left 0
  width 100%
  height 100%
  position fixed
  font-size 15px
  background-color #fff
  z-index 999
  overflow hidden

  .popup-bottom-title
    width 100%
    padding 18px 22px
    display flex
    background-color #ffffff
    border-bottom 1px solid #dcdee2

    .popup-bottom-title-steps
      width 100%
      display flex
      align-items center
      justify-content center

  .popup-bottom-body
    top 81px
    left 0
    width 100%
    height 100%
    position absolute
    overflow auto

.popup-bc
  top 0
  left 0
  width 100%
  height 100%
  position fixed
  z-index 998
  background-color rgba(0, 0, 0, .6)

.outTime
  margin 32px 0
  text-align center
  font-size 22px

.time-button
  margin-top 24px
  padding-bottom 81px
  width 100%
  display flex
  align-items center
  justify-content center

.type-item-wrapper
  padding 24px 84px
  display flex
  align-items center
  justify-content space-between
  flex-wrap wrap

  .type-item
    display flex
    align-items center
    justify-content flex-start

    .type-item-left
      width 168px
      height 168px
      margin-right 18px
      position relative
      overflow hidden
      border-radius 6px

      > img
        width 100%
        height 168px
        object-fit cover

      .type-item-surplus
        bottom 0
        left 0
        width 100%
        position absolute
        padding 8px
        background-color rgba(0, 0, 0, .5)
        color #ffffff
        text-align center
        font-size 14px

      .type-item-surplus-no
        bottom 0
        left 0
        width 100%
        position absolute
        padding 8px
        color #808695
        text-align center
        font-size 14px
        background-color rgba(197, 200, 206, .9)

  .type-item-right
    width calc(100% - 186px)
    height 100%

    h4
      margin-bottom 12px
      font-size 18px

    .price-day
      margin-top 12px
      display flex
      justify-content space-between
      text-align right
      font-size 22px
      div
        display flex
      h5
        color #ed4014
        font-size 22px
      .price-day-label
        font-size 16px
        padding 6px 12px
        border-radius 4px
        background-color #bba37f
        color #ffffff
.room-div
  margin 24px
  padding 12px 24px
  background-color #bba37f
  border-radius 4px
  color #ffffff

.order-item
  width 100%
  display flex
  align-items center
  justify-content space-between
  color #808695

  > span
    color #515a6e

.time-wrapper
  width 100%
  display flex
  align-items flex-start
  justify-content center

  .time-left
    width 20%
    height 100vh
    border-right: 1px solid #e8eaec

    > h4
      margin-top 32px
      text-align center
      font-size 22px

    .time-action
      width 100%
      height calc(100% - 200px)
      display flex
      align-items center

      .time-action-item
        width 100%
        height 150px
        margin 0 auto
        display flex
        align-items center
        justify-content center
        background-color #ffffff
        color #bba37f
        font-size 22px
        border 2px solid #bba37f
        border-radius 0 30px 30px 0

      .time-action-item-yes
        background-color #bba37f !important
        color #ffffff

.pay-img
  display flex
  align-items center
  justify-content center

  p
    text-align center
    font-size 18px

  .pay-img-icon-wrapper
    display flex
    align-items center
    justify-content center

    .pay-img-icon
      width 150px
      height auto

.popup-bottom-time
  font-size 22px
  line-height 44px
  font-weight 600

.type-item-pageAction-left
  top 300px
  left 16px
  position absolute
  font-size 52px
  color #bba37f

.type-item-pageAction-right
  top 300px
  right 16px
  position absolute
  font-size 52px
  color #bba37f

.popupBottomMove-enter-active, .popupBottomMove-leave-active
  transition all .4s

.popupBottomMove-enter, .popupBottomMove-leave-to
  transform translateY(100%)

.popupBottomShow-enter-active, .popupBottomShow-leave-active
  transition all .4s
  opacity 1

.popupBottomShow-enter, .popupBottomShow-leave-to
  opacity 0

.moveTop-enter-active, .moveTop-leave-active
  transition all .4s
  opacity 1

.moveTop-enter, .moveTop-leave-to
  transform translateY(100%)
  opacity 0
</style>

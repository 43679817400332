<template>
    <div>
        <transition name="popupBottomMove">
            <div class="popup-bottom" ref="popup">
                <!--页面顶部-->
                <div class="popup-bottom-title">
                    <p @click="back" class="popup-bottom-title-button">
                        <Button shape="circle" type="primary" size="large" :icon="'ios-arrow-down'">关闭</Button>
                    </p>
                    <div class="popup-bottom-title-steps">
                        <div style="width: 40%">
                            <Steps :current="stepIndex">
                                <Step :title="item.title" :content="item.content" v-for="(item,index) in steps" :key="item.id"></Step>
                            </Steps>
                        </div>
                    </div>
                    <div class="popup-bottom-time">{{backTime}}s</div>
                </div>
                <!--页面内容-->
                <div class="popup-bottom-body" ref="popupBottomWrapper">
                    <div>
                        <Carousel v-model="stepIndex" dots="none" arrow="never">
                            <!--输入入住人姓名-->
                            <CarouselItem>
                                <div class="phone-wrapper">
                                    <h4>请输入您入住的身份证号码</h4>
                                    <div class="phone-img">
                                        <img src="../../assets/name.svg">
                                    </div>
                                    <div class="phone-div">
                                        <div class="phone-div-div">
                                            <Input size="large" v-model="username">
                                                <span slot="prepend">身份证号码</span>
                                            </Input>
                                        </div>
                                    </div>
                                    <transition name="moveTop">
                                        <div class="time-button">
                                            <div style="width: 40%">
                                                <Button type="primary" size="large" long @click="sureName">提交</Button>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </CarouselItem>
                            <!--打开大门-->
                            <CarouselItem>
                                <guide v-if="stepIndex === 1" :roomNum="roomNum"></guide>
                            </CarouselItem>
                        </Carousel>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import loadingPage from  "../loadingPage/loadingPage"
    import continueModel from "../continueModel/continueModel"
    import guide from "../guide/guide"
    import {getLocalStorage, setLocalStorage} from "../../common/js/localStorage"

    export default {
        name: "door",
        components: {
            loadingPage,
            continueModel,
            guide
        },
        data() {
            return {
                hotelId: null,
                steps: [
                    {
                        id: 0,
                        title: '进行中',
                        content: '请刷身份证'
                    },
                    {
                        id: 1,
                        title: '待进行',
                        content: '退房'
                    },
                ],
                stepIndex: 0,
                backTime: 300,
                intervalTime: null,
                topFlag: false,
                roomNum: 8808,
                username: null,
            }
        },
        methods: {
            /*返回首页*/
            back() {
                this.stepIndex = 0
                this.steps[this.stepIndex].title = "进行中"
                this.steps[this.stepIndex + 1].title = "待进行"
                clearInterval(this.intervalTime)
                this.$emit('back')
            },
            //查询订单
            sureName() {
                let obj = {
                    hotelId: this.hotelId,
                    orderStatusList: "2,3,4,5",
                    likeValue: this.username
                }
                let self = this
                this.$http.get('/order/query/continue/order',{
                    params: obj
                }).then(res => {
                    if(res.result.length === 0) {
                        self.$Modal.error({
                            title: '没有找到订单',
                            content: '没有找到订单,请查看身份证号码是否有误',
                            onOk: () => {
                                self.back()
                            }
                        });
                    } else {
                        self.roomNum = res.result[0].roomInfoName;
                        self.stepIndex = 1
                        self.steps[this.stepIndex].title = "进行中"
                        self.steps[this.stepIndex - 1].title = "已完成"
                    }
                })
            },
            /*页面倒计时*/
            pageOutTime() {
                this.intervalTime = setInterval(() => {
                    this.backTime--
                    if(this.backTime <= 0) {
                        clearInterval(this.intervalTime)
                        this.stepIndex = 0
                        this.modalOut = false
                        this.steps[0].title = "进行中"
                        this.steps[1].title = "待进行"
                        this.$emit('back')
                    }
                }, 1000)
            }
        },
        created() {
            let self = this
            setTimeout(() => {
                self.hotelId = getLocalStorage("zs-offline-hotelId")
                self.backTime = 300
                self.pageOutTime()
            }, 200)
            setTimeout(() => {
                self.loadingInit = false
            }, 1000)
        },
        beforeDestroy() {
            clearInterval(this.intervalTime)
            this.loadingInit = true
        }
    }
</script>

<style scoped lang="stylus">
    .popup-bottom
        bottom 0
        left 0
        width 100%
        height 100%
        position fixed
        font-size 15px
        background-color #fff
        z-index 999
        overflow hidden
        .popup-bottom-title
            width 100%
            padding 18px 22px
            display flex
            background-color #ffffff
            border-bottom 1px solid #dcdee2
            .popup-bottom-title-steps
                width 100%
                display flex
                align-items center
                justify-content center
        .popup-bottom-body
            top 81px
            left 0
            width 100%
            height 100%
            position absolute
            overflow auto
    .popup-bc
        top 0
        left 0
        width 100%
        height 100%
        position fixed
        z-index 998
        background-color rgba(0, 0, 0, .6)
    .outTime
        margin 32px 0
        text-align center
        font-size 22px
    .time-button
        margin-top 24px
        padding-bottom 81px
        width 100%
        display flex
        align-items center
        justify-content center
    .type-item-wrapper
        padding 24px 60px
        display flex
        align-items center
        justify-content space-around
        flex-wrap wrap
        .type-item
            display flex
            align-items center
            justify-content flex-start
            .type-item-left
                width 168px
                height 168px
                margin-right 18px
                position relative
                overflow hidden
                border-radius 6px
                > img
                    height 168px
                .type-item-surplus
                    bottom 0
                    left 0
                    width 100%
                    position absolute
                    padding 8px
                    background-color rgba(0,0,0, .5)
                    color #ffffff
                    text-align center
                    font-size 14px
                .type-item-surplus-no
                    bottom 0
                    left 0
                    width 100%
                    position absolute
                    padding 8px
                    color #808695
                    text-align center
                    font-size 14px
                    background-color rgba(197,200,206, .9)
        .type-item-right
            width calc(100% - 186px)
            height 100%
            h4
                margin-bottom 12px
                font-size 18px
            .price-day
                margin-top 12px
                display flex
                justify-content flex-end
                text-align right
                font-size 22px
                h5
                    color #ed4014
                    font-size 22px
    .room-div
        margin 24px
        padding 12px 24px
        background-color #2d8cf0
        border-radius 4px
        color #ffffff
    .order-item
        width 100%
        display flex
        align-items center
        justify-content space-between
        color #808695
        > span
            color #515a6e
    .time-wrapper
        width 100%
        display flex
        align-items flex-start
        justify-content center
        .time-left
            width 20%
            height 100vh
            border-right: 1px solid #e8eaec
            > h4
                margin-top 32px
                text-align center
                font-size 22px
            .time-action
                width 100%
                height calc(100% - 200px)
                display flex
                align-items center
                .time-action-item
                    width 150px
                    height 150px
                    margin 0 auto
                    display flex
                    align-items center
                    justify-content center
                    border-radius 50%
                    background-color #808695
                    color #ffffff
                    font-size 22px
                .time-action-item-yes
                    background-color #2d8cf0 !important
    .zh-type-wrapper
        display flex
        align-items center
        justify-content center
        .zh-type
            height calc(100vh - 81px)
            width 50%
            display flex
            align-items center
            justify-content center
            text-align center
            img
                width 100px
            h4
                margin 20px 0
                font-size 18px
                font-weight bold
            p
                font-size 14px
                font-weight 400
            .zh-type-item
                min-width 350px
    .order-list-wrapper
        margin-top 5%
        padding 0 40px
        display flex
        align-items center
        justify-content space-between
        flex-wrap wrap
        .order-list
            width 400px
            padding 15px
            display flex
            align-items center
            justify-content space-between
            h4
                font-size 18px
    .popup-bottom-time
        font-size 22px
        line-height 44px
        font-weight 600
    .idCard-wrapper
        width 100%
        height 687px
        padding 24px
        overflow hidden
        > h4
            margin-top 24px
            font-size 28px
            text-align center
    .idCard-img-wrapper
        margin-top 24px
        display flex
        align-items center
        justify-content center
        .idCard-img
            width auto
            height 450px
    .tf-label
        margin-top 6px
        font-size 14px
        color #ff9900
    .idCard-submit
        margin-top 40px
        display flex
        align-items center
        justify-content center
    .popupBottomMove-enter-active, .popupBottomMove-leave-active
        transition all .4s
    .popupBottomMove-enter, .popupBottomMove-leave-to
        transform translateY(100%)
    .popupBottomShow-enter-active, .popupBottomShow-leave-active
        transition all .4s
        opacity 1
    .popupBottomShow-enter, .popupBottomShow-leave-to
        opacity 0
    .moveTop-enter-active, .moveTop-leave-active
        transition all .4s
        opacity 1
    .moveTop-enter, .moveTop-leave-to
        transform translateY(100%)
        opacity 0
    .phone-wrapper
        padding 24px
        > h4
            margin 24px 0 48px 0
            font-size 22px
            text-align center
        .phone-img
            margin-bottom 48px
            display flex
            align-items center
            justify-content center
            > img
                width 128px
        .phone-div
            display flex
            align-items center
            justify-content center
            .phone-div-div
                width 50%
        .time-button
            margin-top 60px
            padding-bottom 81px
            width 100%
            display flex
            align-items center
            justify-content center
        .phone-div-top
            margin-top 70px
            width 100%
            display flex
            align-items center
            justify-content center
            .phone-div-div-top
                width 50%
        .moveTop-enter-active, .moveTop-leave-active
            transition all .4s
            opacity 1
        .moveTop-enter, .moveTop-leave-to
            transform translateY(100%)
            opacity 0
</style>

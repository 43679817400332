<template>
  <div>
    <!--语音播报-->
    <audio autoplay="autoplay" v-show="false">
      <source src="../../assets/audio/guide.mp3" type="audio/mpeg">
    </audio>
    <h1 class="guide-title">{{hotelOpenDoorType === 1 ? "查看开门密码" : "开门指引"}}</h1>
    <div v-if="hotelOpenDoorType === 2 || hotelOpenDoorType === 3" class="guide-wrapper">
      <div>
        <img src="../../assets/hotelCard.jpeg">
        <h2 style="margin-top: 10px">拿到酒店房卡,刷卡入住</h2>
      </div>
    </div>
    <div v-if="hotelOpenDoorType === 1" class="guide-wrapper">
      <div class="guide-item">
        <div style="height: 400px;margin-bottom: 25px;display: flex;align-items: center">
          <img class="guide-item-img" src="../../assets/code-mp.jpg" style="height: 200px;width: auto">
        </div>
        <h2 class="guide-item-font">请微信扫码</h2>
      </div>
      <div class="guide-item">
        <img class="guide-item-img" src="../../assets/end1.jpg" style="height: 400px;width: auto">
        <h2 class="guide-item-font">点击关注</h2>
      </div>
      <div class="guide-item">
        <img class="guide-item-img" src="../../assets/end2.png" style="height: 400px;width: auto">
        <h2 class="guide-item-font">点击开门</h2>
      </div>
      <div class="guide-item">
        <h4 class="guide-item-room">{{roomNum}}</h4>
        <img class="guide-item-img" src="../../assets/guide1.png" style="height: 400px">
        <h2 class="guide-item-font">入住房间</h2>
      </div>
    </div>
  </div>
</template>

<script>
  import {getLocalStorage} from "../../common/js/localStorage";
  import md5 from "js-md5";
  import axios from "axios";

  export default {
    name: "guide",
    props: {
      roomNum: String
    },
    data() {
      return {
        hotelOpenDoorType: 2
      }
    },
    methods: {
      getSign(obj) {
        let str = ""
        obj = this.sortASCII(obj)
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (key != "SIGN") {
              if (key == "APPID") {
                str = key + '=' + obj[key]
              } else {
                str += '&' + key + '=' + obj[key]
              }
            }
          }
        }
        let stringSignTemp = str + "&APPKEY=EE0D077AF15F49D7B95301F2B819AE54";
        return md5(stringSignTemp).toUpperCase();
      },
      sortASCII(obj) {
        var arr = new Array();
        var num = 0;
        for (var i in obj) {
          arr[num] = i;
          num++;
        }
        var sortArr = arr.sort();
        var sortObj = {};
        for (var i in sortArr) {
          sortObj[sortArr[i]] = obj[sortArr[i]];
        }
        return sortObj;
      },
      openDoor() {
        let self = this
        let obj = {
          APPID: "03969B8FA1FA49F4A19B2DA500157CCB",
          AT: new Date().getTime(),
          NONCESTR: new Date().getTime(),
          USERNAME: "17683115044",
          PASSWORD: "123456"
        }
        let params = new URLSearchParams();
        params.append('APPID', "03969B8FA1FA49F4A19B2DA500157CCB");
        params.append('AT', new Date().getTime());
        params.append('NONCESTR', new Date().getTime());
        params.append('USERNAME', "17683115044");
        params.append('PASSWORD', "123456");
        params.append('SIGN', this.getSign(obj));
        axios.post("/apis/dms/app/dmsLogin", params).then(res => {
          let baseParams = {
            APPID: "03969B8FA1FA49F4A19B2DA500157CCB",
            AT: new Date().getTime(),
            DEVICE_ID: "FE983198",
            MASTERSLAVE: 0,
            NONCESTR: new Date().getTime(),
            OPENCLOSEACTION: 1,
            TOKEN: res.data.token
          }
          let paramsRee = new URLSearchParams();
          paramsRee.append('APPID', "03969B8FA1FA49F4A19B2DA500157CCB");
          paramsRee.append('AT', new Date().getTime());
          paramsRee.append('DEVICE_ID', "FE983198");
          paramsRee.append('MASTERSLAVE', 0);
          paramsRee.append('NONCESTR', new Date().getTime());
          paramsRee.append('OPENCLOSEACTION', 1);
          paramsRee.append('TOKEN', res.data.token);
          paramsRee.append('SIGN', this.getSign(baseParams));
          axios.post("/apis/dms/app/openRemoteDeviceLock", paramsRee).then(ree => {
            if(ree.data.result === 0) {
              self.$Message.success("大门已开，请尽快开门");
            } else {
              self.$Message.error("开门失败");
            }
          })
        })
      }
    },
    mounted() {
      this.hotelOpenDoorType = getLocalStorage('zs-offline-hotelDetailsObj').hotelOpenDoorType;
      this.openDoor();
    }
  }
</script>

<style scoped>
  .guide-title {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    color: #17233d;
  }

  .guide-wrapper {
    padding: 30px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    font-size: 14px;
    color: #17233d;
  }

  .guide-item{
    position: relative;
  }

  .guide-item-img {
    position: relative;
    width: 200px;
    margin-bottom: 20px;
  }

  .guide-item-right{
    position: relative;
    width: 150px;
    margin-bottom: 20px;
  }

  .guide-item-room {
    top: 30px;
    left: 0;
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 999;
    color: #ffffff;
    letter-spacing: 2px;
    font-size: 22px;
  }
</style>
